import React, { useState } from 'react';
import openai from '../../config/openaiConfig';
import { checkRunStatusOnMagicDraft } from '../../utilityFunctions/openAiUtilities';

const AiComponentEditorModal = ({ 
    setShowAiComponentEditorModal, 
    assistantFiles,     
    setShowLoadingModal, 
    currentResponseText,
    assistantId,
    prepopulatedInstructions,
    setPrepopulatedInstructions

}) => {
    
    const [aiGeneratedResponse, setAiGeneratedResponse] = useState(null)

    const handleGenerateButtonClicked = async () => {
        
        const editingInstructions = document.getElementById('editingInstructions').value; // Get the value from the textarea
        if (!editingInstructions.trim()) {
            alert("Editing instructions cannot be empty.");
            return;
        }
        
        document.getElementById('editingInstructions').disabled = true; // Disable the textarea
        setShowLoadingModal(true)
        
        const messagePayload = {
            role: "user",
            content: 
                `
                    Edit the following text using these instructions ${editingInstructions}. Text to edit: 
                    ${currentResponseText}, using 
                    
                `,
                // attachments: assistantFiles.length > 0 ? assistantFiles.map(file => ({ file_id: file.id, tools: [{ type: "file_search" }] })) : undefined,
        };
    
        const response = await openai.chat.completions.create({
            model: "gpt-4o",
            messages: [
                { role: "system", content: "You are the world's best grant writer, working for a nonprofit."},
                // { role: "user", content: "You are applying for a grant opportunity from the following funder: ", funderDetails},
                { role: "user", content: `Edit the following text using these instructions: ${editingInstructions}. Text to edit: ${currentResponseText}`},
                // { role: "user", content: `Write a clear and concise ${documentType}, and consider the following additional instructions: ${instructions}`},
                // { role: "user", content: `Example output: [{"question": "What is your name?"}, {"question": "Describe your project."}]` }
            ],
            temperature: 0.4,
            // max_tokens: 150, // Adjust as needed
            n: 1,
        });
    
        if (!response || !response.choices || response.choices.length === 0) {
            throw new Error("Invalid response from OpenAI API: 'response' or 'response.choices' is undefined");
        }
    
        const result = response.choices[0].message.content;
        setShowLoadingModal(false)
        setAiGeneratedResponse(result)
    }

    return (
        <div className="bg-gray-900 bg-opacity-50" 
        style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: '9999', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        onClick={() => {
            setShowAiComponentEditorModal(false);
            setPrepopulatedInstructions("");
        }}
   >
       
       {/* Modal */}
       <div className='text-left bg-white rounded-lg p-8' 
            style={{ width: '80vw', height: '80vh', overflowY: 'auto' }}
            onClick={(e) => e.stopPropagation()}>
           
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button onClick={() => setShowAiComponentEditorModal(false)} className="py-2 px-4 mb-4 bg-gray-500 text-white rounded-lg">Close</button>
                </div>
                <div>                    
                    <div className='bg-gray-200 p-8 text-left mb-6 rounded-lg'>
                        <p className='font-bold text-sm mb-4'>CURRENT VALUE</p>
                        <p className=''>{currentResponseText}</p>
                    </div>
                    
                    <div className='bg-gray-200 p-8 text-left mb-6 rounded-lg'>
                        <p className='font-bold text-sm'>EDITING INSTRUCTIONS</p>
                        <textarea 
                            id="editingInstructions" 
                            className="border border-black mt-4 w-full h-40 p-2 rounded-lg" 
                            placeholder="Make my text much sillier, and 50% shorter, so that it's about ~100 words"
                            defaultValue={prepopulatedInstructions || ''}
                        ></textarea>
                    </div>

                    <div className='bg-gray-200 p-8 text-left rounded-lg'>
                        <p className='font-bold text-sm'>DAISY'S RESPONSE</p>
                        {aiGeneratedResponse 
                            ? <div>
                                <p className='italic text-gray-500 mb-4 text-sm'>Copy and paste if you like this response</p> 
                                <p>{aiGeneratedResponse}</p> 
                            </div>
                            : <button onClick={handleGenerateButtonClicked} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Generate</button>
                        }
                    </div>                                        
                </div>                
            </div>
        </div>
    );
};

export default AiComponentEditorModal;