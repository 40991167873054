import React, { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js';

// Initialize Supabase client
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

function AwardsAdvancedSearchCriteria({ 
    setFetchedAwards 
}) {    
    const [query, setQuery] = useState({
        recipient_name: '',
        recipient_ein: '',
        grant_size_min: '',
        grant_size_max: '',
        purpose: '',
        city_name: '',
        state_abbreviation_cd: '',
        zip_code: '',
        funder_ein: '',
        tax_year: ''
    });

    const fetchAwards = async () => {
        let queryBuilder = supabase.from('awards').select('*').range(0, 1000);

        Object.keys(query).forEach(key => {
            if (query[key] && key !== 'grant_size_min' && key !== 'grant_size_max') {
                console.log(`Applying filter: ${key} ilike %${query[key]}%`);
                queryBuilder = queryBuilder.ilike(key, `%${query[key]}%`);
            }
        });

        if (query.grant_size_min) {
            console.log(`Applying filter: cash_grant_amt >= ${query.grant_size_min}`);
            queryBuilder = queryBuilder.gte('cash_grant_amt', query.grant_size_min);
        }
        if (query.grant_size_max) {
            console.log(`Applying filter: cash_grant_amt <= ${query.grant_size_max}`);
            queryBuilder = queryBuilder.lte('cash_grant_amt', query.grant_size_max);
        }

        const { data, error } = await queryBuilder;
        if (error) {
            console.error('Error fetching awards:', error);
        } else {
            if (typeof setFetchedAwards === 'function') {
                setFetchedAwards(data);
            } else {
                console.error('setFetchedAwards is not a function');
            }
        }
    };

    // Debounce the fetchAwards function to prevent calls with every keystroke
    useEffect(() => {
        const handler = setTimeout(() => {
            fetchAwards();
        }, 500); 

        return () => {
            clearTimeout(handler);
        };
    }, [query]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setQuery(prevQuery => ({ ...prevQuery, [name]: value }));
    };

    return (
        <div className="bg-gray-100 p-12 text-left mt-4 flex flex-row space-x-4 w-full gap-24 rounded-lg">                        
            {/* Recipient Info */}
            <div className="flex-1">
                <p className='text-xl font-bold'>Recipient Info</p>
                <hr className='my-2'></hr>
                <div className="mb-2">
                    <label className="block text-sm font-medium text-gray-700">State</label>
                    <select
                        name="state_abbreviation_cd"
                        value={query.state_abbreviation_cd}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                    >
                        <option value="">Select a state</option>
                        <option value="AL">AL</option>
                        <option value="AK">AK</option>
                        <option value="AZ">AZ</option>
                        <option value="AR">AR</option>
                        <option value="CA">CA</option>
                        <option value="CO">CO</option>
                        <option value="CT">CT</option>
                        <option value="DE">DE</option>
                        <option value="FL">FL</option>
                        <option value="GA">GA</option>
                        <option value="HI">HI</option>
                        <option value="ID">ID</option>
                        <option value="IL">IL</option>
                        <option value="IN">IN</option>
                        <option value="IA">IA</option>
                        <option value="KS">KS</option>
                        <option value="KY">KY</option>
                        <option value="LA">LA</option>
                        <option value="ME">ME</option>
                        <option value="MD">MD</option>
                        <option value="MA">MA</option>
                        <option value="MI">MI</option>
                        <option value="MN">MN</option>
                        <option value="MS">MS</option>
                        <option value="MO">MO</option>
                        <option value="MT">MT</option>
                        <option value="NE">NE</option>
                        <option value="NV">NV</option>
                        <option value="NH">NH</option>
                        <option value="NJ">NJ</option>
                        <option value="NM">NM</option>
                        <option value="NY">NY</option>
                        <option value="NC">NC</option>
                        <option value="ND">ND</option>
                        <option value="OH">OH</option>
                        <option value="OK">OK</option>
                        <option value="OR">OR</option>
                        <option value="PA">PA</option>
                        <option value="RI">RI</option>
                        <option value="SC">SC</option>
                        <option value="SD">SD</option>
                        <option value="TN">TN</option>
                        <option value="TX">TX</option>
                        <option value="UT">UT</option>
                        <option value="VT">VT</option>
                        <option value="VA">VA</option>
                        <option value="WA">WA</option>
                        <option value="WV">WV</option>
                        <option value="WI">WI</option>
                        <option value="WY">WY</option>
                    </select>
                </div>
                <div className="mb-2">
                    <label className="block text-sm font-medium text-gray-700">City</label>
                    <input
                        type="text"
                        name="city_name"
                        value={query.city_name}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                    />
                </div>

                <div className="mb-2">
                    <label className="block text-sm font-medium text-gray-700">Zip Code</label>
                    <input
                        type="text"
                        name="zip_code"
                        value={query.zip_code}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                    />
                </div>
                <div className="mb-2">
                    <label className="block text-sm font-medium text-gray-700">Recipient Name</label>
                    <input
                        type="text"
                        name="recipient_name"
                        value={query.recipient_name}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                        />                
                </div>                
            </div>
            
            {/* Grant Info */}
            <div className="flex-1">
                <p className='text-xl font-bold'>Grant Info</p>
                <hr className='my-2'></hr>
                <div>
                    <div className="mb-2">
                        <label className="block text-sm font-medium text-gray-700">Grant Size Min</label>
                        <input
                            type="text"
                            name="grant_size_min"
                            value={query.grant_size_min}
                            onChange={handleInputChange}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                        />
                    </div>
                    <div className="mb-2">
                        <label className="block text-sm font-medium text-gray-700">Grant Size Max</label>
                        <input
                            type="text"
                            name="grant_size_max"
                            value={query.grant_size_max}
                            onChange={handleInputChange}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                        />
                    </div>

                    <div className="mb-2">
                        <label className="block text-sm font-medium text-gray-700">Tax Year</label>
                        <input
                            type="text"
                            name="tax_year"
                            value={query.tax_year}
                            onChange={handleInputChange}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                        />
                    </div>
                    <div className="mb-2">
                        <label className="block text-sm font-medium text-gray-700">Purpose</label>
                        <input
                            type="text"
                            name="purpose"
                            value={query.purpose}
                            onChange={handleInputChange}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                            />
                    </div>  
                </div>
            </div>
                            
            {/* Advanced Criteria */}
            <div className="flex-1">
                <p className='text-xl font-bold'>Advanced Criteria</p>
                <hr className='my-2'></hr>
                <div className="mb-2">
                    <label className="block text-sm font-medium text-gray-700">Recipient EIN</label>
                    <input
                        type="text"
                        name="recipient_ein"
                        value={query.recipient_ein}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                    />
                </div>
                <div className="mb-2">
                    <label className="block text-sm font-medium text-gray-700">Funder EIN</label>
                    <input
                        type="text"
                        name="funder_ein"
                        value={query.funder_ein}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                        />
                    </div>
            </div>
        </div>

    );
}

export default AwardsAdvancedSearchCriteria;