import React, { useState, useContext, useEffect } from 'react';

// Import functions
import {  fetchClientProgramsFromFirestore, fetchGrantProposalByIdFromFirestore, fetchProposalComponentsFromFirestore, fetchAssistantDetailsFromFirestoreForOrganization, fetchClientOrganizationsFromFirestore, fetchGrantProposalsFromFirestoreForOrganization, createGrantProposalInFirestore, createGrantProposalComponentInFirestore, markFileAsDeletedInFirestore, uploadFileToFirebaseStorage, saveOpenAiFileIdToFirestore, saveFileMetadataToFirestore, fetchAssistantDetailsFromFirestore, fetchMembershipDetailsFromFirestore, fetchOrganizationDetailsFromFirestore, fetchMembershipDetailsFromFirestoreForOrganization } from '../utilityFunctions/firestoreUtilities';

// Import configs
import { AuthContext } from '../AuthContext';
import { auth } from '../config/firebaseConfig'; 

// Import components
import OpportunityAndFunderModal from './GrantDiscoveryComponents/OpportunityAndOrganizationModal';
import AwardsAdvancedSearch from './AwardsSearch/AwardsAdvancedSearch';
import OnboardingFlow from './HelperComponents/OnboardingFlow';
import LoadingModal from './HelperComponents/LoadingModal';
import ManageProposalModal from './OrganizerTools/ManageProposalModal';
import PaywallComponent from './HelperComponents/PaywallComponent';
import SideNavBar from './SideNavBar';
import LoggedOutWelcome from './AuthComponents/LoggedOutWelcome';
import ProposalsDashboard from './OrganizerTools/ProposalsDashboard';
import MfdComponentSelectorModal from './GrantWritingComponents/MfdComponentSelectorModal';
import ClientOrganizationsPickerModal from './ClientOrganizationsPickerModal';
import CreateProgramModal from './GrantDiscoveryComponents/CreateProgramModal';
import AccountSettings from './HelperComponents/AccountSettings';
import UserSimulator from './HelperComponents/UserSimulator';
import AddMoreGrantsModal from './OrganizerTools/AddMoreGrantsModal';
import MobileWelcome from './AuthComponents/MobileWelcome';
import SelectedProposalPage from './GrantWritingComponents/SelectedProposalPage';
import RfpsSearch from './GrantDiscoveryComponents/RrfpsSearch';

// Utility function to detect mobile devices
const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

function Home() {

  const { authUser } = useContext(AuthContext);  

  // Top level state variables
  const [currentView, setCurrentView] = useState('proposalsDashboard'); 
  const [membershipDetails, setMembershipDetails] = useState(null);
  const [clientOrganizationsBelongedTo, setClientOrganizationsBelongedTo] = useState([]);
  const [currentClientOrganization, setCurrentClientOrganization] = useState(null);
  const [organizationPrograms, setOrganizationPrograms] = useState([]);

  // Grant discovery state variables
  const [selectedOpportunity, setSelectedOpportunity] = useState()
  const [selectedOrganizationEin, setSelectedOrganizationEin] = useState(); 
  const [currentProgram, setCurrentProgram] = useState(null)
  const [programToModify, setProgramToModify] = useState(null)

  // Grant writing state variables
  const [grantProposals, setGrantProposals] = useState([]);
  const [currentProposal, setCurrentProposal] = useState();
  // const [proposalTextComponents, setProposalTextComponents] = useState([]);
  const [currentProposalComponents, setCurrentProposalComponents] = useState([]);

    // Modal state variables
  const [showLoadingModal, setShowLoadingModal] = useState(false)
  const [showOpportunityAndFunderModal, setShowOpportunityAndFunderModal] = useState(false)
  const [showManageProposalModal, setShowManageProposalModal] = useState(false)  
  const [showPaywallModal, setShowPaywallModal] = useState(false)
  const [showClientOrganizationsPickerModal, setShowClientOrganizationsPickerModal] = useState(false)
  const [showCreateProgramModal, setShowCreateProgramModal] = useState(false)
  const [showAddMoreGrantsModal, setShowAddMoreGrantsModal] = useState(false)
  const [showOnboardingFlow, setShowOnboardingFlow] = useState(false);
  const [showMfdComponentSelectorModal, setShowMfdComponentSelectorModal] = useState(false);

  // When authUser changes, get client organizations
  useEffect(() => {        
    if (authUser) { 
      if (authUser.uid) { 
        fetchClientOrganizationsBelongedTo(authUser.uid);
      }
    }
  }, [authUser]); 

  // Define current client organization
  useEffect(() => {
    if (clientOrganizationsBelongedTo.length > 0) {
      setCurrentClientOrganization(clientOrganizationsBelongedTo[0]) 
      console.log("currentClientOrganization set to: ", currentClientOrganization)
    }
  }, [clientOrganizationsBelongedTo]);

  // Once current client organization set, fetch programs, membership details, and refresh grant proposals
  useEffect(() => {
    if (currentClientOrganization) {
      fetchProgramsForOrganization(currentClientOrganization.id);
      fetchMembershipDetails()
      refreshGrantProposals()
    }
  }, [currentClientOrganization]);

  // Define current program
  useEffect(() => {
    if (!currentProgram && organizationPrograms.length > 0) {
        setCurrentProgram(organizationPrograms[0]);
    }
}, [currentProgram, organizationPrograms]);

  // If selectedOpportunity changes, also change selectedOrganizationEin
  useEffect(() => {
    if (selectedOpportunity) {
      setSelectedOrganizationEin(selectedOpportunity.ein)
    }
  }, [selectedOpportunity]);

  // If selectedOrganizationEin changes, show OpportunityAndFunderModal
  useEffect(() => {
    if (selectedOrganizationEin) {      
      setShowOpportunityAndFunderModal(true);
    }
  }, [selectedOrganizationEin]);

  // If currentProposal changes, set currentView to selectedProposalPage
  useEffect(() => {
    console.log("currentProposal changed: ", currentProposal)
    if (currentProposal) {
      setCurrentView('selectedProposalPage');
    }
  }, [currentProposal]);

  // Other Functions
  const fetchProgramsForOrganization = async (organizationId) => {
    console.log("Fetching client programs for organization ID:", organizationId);
    const clientPrograms = await fetchClientProgramsFromFirestore(organizationId);
    console.log("Fetched client programs:", clientPrograms);
    setOrganizationPrograms(clientPrograms);

    // Show onboarding flow if no programs are found
    if (!clientPrograms || clientPrograms.length === 0) {
      setShowOnboardingFlow(true);
    } else {
      setShowOnboardingFlow(false);
    }

    return clientPrograms;
  };


  const refreshGrantProposals = async () => {
    if (!currentClientOrganization || !currentClientOrganization.id) {
        console.error("Current client organization is not set.");
        setShowOnboardingFlow(true);
        return;
    }
    const fetchedGrantProposals = await fetchGrantProposalsFromFirestoreForOrganization(currentClientOrganization.id);
    console.log("fetchedGrantProposals: ", fetchedGrantProposals);
    setGrantProposals(fetchedGrantProposals);
};

  const refreshProposalComponents = async () => {
    const fetchedProposalComponents = await fetchProposalComponentsFromFirestore(currentProposal);
    console.log("Fetched Proposal Components from PE: ", fetchedProposalComponents);
    setCurrentProposalComponents(fetchedProposalComponents)
}
  
  // Main function to fetch clientOrganizations which user belongs to
  const fetchClientOrganizationsBelongedTo = async (uid) => {
    try {
      const clientOrganizations = await fetchClientOrganizationsFromFirestore(uid);
        setClientOrganizationsBelongedTo(clientOrganizations)
    } catch (error) {
        console.error("Error fetching client organizations: ", error);
    }
  };

  // Main function to fetch plan details
  const fetchMembershipDetails = async () => {
    if (!auth.currentUser || !currentClientOrganization) {
        console.error("No current user / current clientOrg found.");
        return;
    }
    try {
        const membershipDetails = await fetchMembershipDetailsFromFirestoreForOrganization(currentClientOrganization.id);
        setMembershipDetails(membershipDetails)
    } catch (error) {
        console.error("Error fetching membership details: ", error);
    }
  };
  
    const renderView = () => {
      if (isMobileDevice()) {
        return <MobileWelcome />;
      }

      switch (currentView) {
        case 'awardsAdvancedSearch':
          return <AwardsAdvancedSearch 
            setSelectedOrganizationEin={setSelectedOrganizationEin}
            selectedOrganizationEin={selectedOrganizationEin}
          />;

        case 'accountSettings':
          return <AccountSettings                
            currentClientOrganization={currentClientOrganization}
            membershipDetails={membershipDetails}
            // setShowClientOrganizationsPickerModal={setShowClientOrganizationsPickerModal}
          />;
        case 'rfpsSearch':
          return <RfpsSearch 
            currentProgram={currentProgram}  
            membershipDetails={membershipDetails}
            setShowOpportunityAndFunderModal={setShowOpportunityAndFunderModal}
            setSelectedOpportunity={setSelectedOpportunity}            
          />;
        
        case 'selectedProposalPage':
          return <SelectedProposalPage 
            currentProposal={currentProposal}
            refreshGrantProposals={refreshGrantProposals}
            setCurrentProposal={setCurrentProposal}
            setCurrentView={setCurrentView}
            setShowLoadingModal={setShowLoadingModal}
            setShowManageProposalModal={setShowManageProposalModal}
            setShowMfdComponentSelectorModal={setShowMfdComponentSelectorModal}
            refreshProposalComponents={refreshProposalComponents}
            currentProposalComponents={currentProposalComponents}
            // setCurrentProposalComponents={setCurrentProposalComponents}
          />;
        case 'proposalsDashboard':
          return <ProposalsDashboard 
            authUser={authUser}
            setCurrentProposal={setCurrentProposal}
            currentClientOrganization={currentClientOrganization}
            currentProposal={currentProposal}
            grantProposals={grantProposals}
            refreshGrantProposals={refreshGrantProposals}
            setShowAddMoreGrantsModal={setShowAddMoreGrantsModal}
          />;
      }
  };

    return (
      <div className="flex" style={{ height: '100vh', width: '100vw', overflow: 'hidden' }}>
        
        {/* Modals */}
        <div>          
          {/* {true && (
            <UserSimulator 
              fetchClientOrganizationsBelongedTo={fetchClientOrganizationsBelongedTo}              
            />
          )} */}

          {showLoadingModal && (
            <LoadingModal              
            />
          )}

          {showPaywallModal && (
            <PaywallComponent
              onClose={() => setShowPaywallModal(false)}           
            />
          )}

          {showAddMoreGrantsModal && (
            <AddMoreGrantsModal
              setCurrentView={setCurrentView}
              setShowAddMoreGrantsModal={setShowAddMoreGrantsModal}
              authUser={authUser}
              currentClientOrganization={currentClientOrganization}
              refreshGrantProposals={refreshGrantProposals}
            ></AddMoreGrantsModal>
          )}


          {showCreateProgramModal && (
            <CreateProgramModal         
              currentClientOrganization={currentClientOrganization} 
              existingProgram={programToModify}
              fetchProgramsForOrganization={fetchProgramsForOrganization}
              setCurrentProgram={setCurrentProgram}
              setShowCreateProgramModal={setShowCreateProgramModal}                            
            />
          )}


          {showClientOrganizationsPickerModal && (
            <ClientOrganizationsPickerModal         
              clientOrganizationsBelongedTo={clientOrganizationsBelongedTo}
              fetchClientOrganizationsBelongedTo={fetchClientOrganizationsBelongedTo}
              setCurrentClientOrganization={setCurrentClientOrganization}
              setShowClientOrganizationsPickerModal={setShowClientOrganizationsPickerModal}
              membershipDetails={membershipDetails}
              setShowPaywallModal={setShowPaywallModal}
            />
          )}
          
          {showManageProposalModal && (
            <ManageProposalModal
              currentProposal={currentProposal}  
              fetchGrantProposalByIdFromFirestore={fetchGrantProposalByIdFromFirestore}
              setCurrentProposal={setCurrentProposal}
              setShowManageProposalModal={setShowManageProposalModal}            
            />
          )}

          {showOpportunityAndFunderModal && (
            <OpportunityAndFunderModal
              membershipDetails={membershipDetails}
              selectedOpportunity={selectedOpportunity}
              selectedOrganizationEin={selectedOrganizationEin}
              setSelectedOpportunity={setSelectedOpportunity}
              setShowLoadingModal={setShowLoadingModal}
              setSelectedOrganizationEin={setSelectedOrganizationEin}
              setShowOpportunityAndFunderModal={setShowOpportunityAndFunderModal}
              setShowPaywallModal={setShowPaywallModal}
            />
          )}

          {showMfdComponentSelectorModal && (
            <MfdComponentSelectorModal
              setShowMfdComponentSelectorModal={setShowMfdComponentSelectorModal}              
              currentProgram={currentProgram}
              currentProposal={currentProposal}        
              setShowLoadingModal={setShowLoadingModal}
              refreshProposalComponents={refreshProposalComponents}
              // setProposalTextComponents={setProposalTextComponents}
            />
          )}

        </div>           
        <SideNavBar 
            currentProgram={currentProgram}
            currentView={currentView}
            currentClientOrganization={currentClientOrganization}
            membershipDetails={membershipDetails}
            organizationPrograms={organizationPrograms}
            setCurrentProgram={setCurrentProgram}
            setCurrentView={setCurrentView}                         
            setProgramToModify={setProgramToModify}
            setShowClientOrganizationsPickerModal={setShowClientOrganizationsPickerModal}          
            setShowCreateProgramModal={setShowCreateProgramModal}
            setShowPaywallModal={setShowPaywallModal}
        />
        <div style={{ flex: 1, overflowY: 'auto' }}>
          {authUser ? (
            showOnboardingFlow ? (
              <OnboardingFlow 
                fetchClientOrganizationsBelongedTo={fetchClientOrganizationsBelongedTo}
                fetchProgramsForOrganization={fetchProgramsForOrganization}                             
                setShowLoadingModal={setShowLoadingModal}
              />
            ) : (
              <div>
                {renderView()}
              </div>
            )
          ) : (
            <LoggedOutWelcome></LoggedOutWelcome>
          )}
        </div>
      </div>
    );
}

export default Home;