const LoggedOutWelcome = () => {
    
    return (    
        <div className='AppInterface flex flex-col justify-center items-center' style={{ width: '100%', height: '92vh' }}>
            <div className="text-5xl font-bold">
                Welcome to GrantMagic 👋              
            </div>
            <div className='text-xl text-gray-500 my-4'>AI tools to help nonprofits to win more grant funding</div>
            <div className='mt-12 text-xl'>
                <a href="/login" className="text-indigo-600">Login</a> or <a href="/signup" className="text-indigo-600">Sign Up</a> to get started.
            </div>
        </div>
    );
};

export default LoggedOutWelcome;
