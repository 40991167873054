import React, { useState } from 'react';
import { createGrantProposalComponentInFirestore, fetchProposalComponentsFromFirestore } from '../../utilityFunctions/firestoreUtilities';
import { generateFirstDraftDocumentWithOpenAi } from '../../utilityFunctions/openAiUtilities';

const MfdComponentSpecificationModal = ({ 
    selectedOption, 
    setShowMfdComponentSpecificationModal, 
    setShowMfdComponentSelectorModal,
    currentProgram,
    currentProposal,
    setShowLoadingModal,
    refreshProposalComponents
}) => {
    const [instructions, setInstructions] = useState('');
    const [grantFunderDetails, setGrantFunderDetails] = useState('');

    const handleGenerate = async () => {        
        setShowLoadingModal(true);
        const documentText = await generateFirstDraftDocumentWithOpenAi(currentProgram, selectedOption, grantFunderDetails, instructions);
        await createGrantProposalComponentInFirestore(currentProposal.id, selectedOption, documentText);        
        // const fetchedProposalComponents = await fetchProposalComponentsFromFirestore(currentProposal)
        await refreshProposalComponents();
        setShowLoadingModal(false);
        setShowMfdComponentSpecificationModal(false);
        setShowMfdComponentSelectorModal(false);
    };

    return (
        <div 
            className="modal-overlay" 
            style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            onClick={() => setShowMfdComponentSpecificationModal(false)}
        >
            <div 
                className="modal text-left p-12" 
                style={{ cursor: 'default', width: '80vw', height: 'auto', backgroundColor: 'white', borderRadius: '10px' }}
                onClick={(e) => e.stopPropagation()}
            >
                <h2 className='text-xl py-4'>Create a magic first draft ✨</h2>
                <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">
                        For my program...
                    </label>
                    <div className="shadow-sm block w-full py-2 border-gray-300 bg-gray-100 text-gray-500 pl-3 rounded-md">
                        {currentProgram ? currentProgram.programName : 'No program selected'}
                    </div>
                </div>
                <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">
                        Create a...
                    </label>
                    <div className="shadow-sm block w-full py-2 border-gray-300 bg-gray-100 text-gray-500 pl-3 rounded-md">
                        {selectedOption === "Custom" ? `${selectedOption} document` : selectedOption}
                    </div>
                </div>

                <div className="mt-4">
                    <label htmlFor="grantFunderDetails" className="block text-sm font-medium text-gray-700">
                        Grant / Funder Details
                    </label>
                    <textarea
                        id="grantFunderDetails"
                        className="shadow-sm block w-full py-2 border-gray-300 border-2 rounded-md"
                        placeholder="Include details about the grant / funder that you want Daisy to use to personalize your document."
                        value={grantFunderDetails}
                        onChange={(e) => setGrantFunderDetails(e.target.value)}
                    />
                </div>

                <div className="mt-4">
                    <label htmlFor="instructions" className="block text-sm font-medium text-gray-700">
                        Additional instructions
                    </label>
                    <textarea
                        id="instructions"
                        className="shadow-sm block w-full py-2 border-gray-300 border-2 rounded-md"
                        placeholder="Enter instructions"
                        value={instructions}
                        onChange={(e) => setInstructions(e.target.value)}
                    />
                </div>
                <div className="flex justify-center mt-8">
                    <button
                        onClick={handleGenerate}
                        className="bg-blue-500 text-white py-2 px-4 rounded"
                    >
                        Generate
                    </button>
                </div>
                <button onClick={() => setShowMfdComponentSpecificationModal(false)} className="text-gray-500">Cancel</button>
            </div>
        </div>
    );
};

export default MfdComponentSpecificationModal;