import {  useContext } from 'react';
import { createClientOrganizationInFirestore } from '../utilityFunctions/firestoreUtilities';
import { AuthContext } from '../AuthContext';

const ClientOrganizationsPickerModal = ({ 
        clientOrganizationsBelongedTo,        
        fetchClientOrganizationsBelongedTo,
        setCurrentClientOrganization,
        setShowClientOrganizationsPickerModal,
        setShowPaywallModal,
        membershipDetails
    }) => {

    const { authUser } = useContext(AuthContext);

    const handleCreateNewOrganizationClicked = async () => {
        if (!membershipDetails) {
            setShowClientOrganizationsPickerModal(false)
            setShowPaywallModal(true);
            return;
        }

        const organizationName = prompt("Enter the name of the new organization:");
        if (organizationName) {
            console.log("Create new org:", organizationName);
            await createClientOrganizationInFirestore(organizationName, authUser.uid);
            await fetchClientOrganizationsBelongedTo(authUser.uid);             
        }
    };    

    const handleOrganizationButtonClicked = (organization) => {
        setCurrentClientOrganization(organization)
        setShowClientOrganizationsPickerModal(false)
    };

    return (
        <div 
            className="modal-overlay" 
            style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}
            onClick={() => setShowClientOrganizationsPickerModal(false)}
        >
            <div 
                className="modal p-8" 
                style={{ cursor: 'default', width: '50vw', height: '50vh', backgroundColor: 'white', borderRadius: '10px' }}
                onClick={(e) => e.stopPropagation()} // Prevent click event from propagating to the overlay
            >
                <div className="flex justify-end items-center space-x-4">
                    <button 
                        onClick={handleCreateNewOrganizationClicked} 
                        className="text-white bg-indigo-500 hover:bg-blue-700 text-sm font-bold py-2 px-4 rounded"
                    >
                        Create New
                    </button>
                    <button onClick={() => setShowClientOrganizationsPickerModal(false)} className="text-white bg-gray-500 hover:bg-gray-700 text-sm font-bold py-2 px-4 rounded">
                        Close
                    </button>
                </div>
                
                <div className='mt-10'>
                    <p className='text-sm text-gray-400 font-bold text-left'>YOUR ORGANIZATIONS</p>
                    {clientOrganizationsBelongedTo && clientOrganizationsBelongedTo.length > 0 ? (
                        clientOrganizationsBelongedTo.map((organization, index) => (
                            <button 
                                key={index} 
                                onClick={() => handleOrganizationButtonClicked(organization)} // Pass organization here
                                className='mt-5 p-2 rounded-lg w-full hover:bg-gray-200 flex items-center'
                            >
                                <div className='bg-gray-500 rounded-lg text-xs py-1 px-2'>{organization.name[0].toUpperCase()}</div>
                                <h2 className="font-bold ml-2">{organization.name}</h2>
                            </button>
                        ))
                    ) : (
                        <p className='mt-5 text-gray-500'>No organizations found.</p>
                    )}
                </div>
                
            </div>                
       </div>
    );
};

export default ClientOrganizationsPickerModal;
