import gmlogo from '../../assets/gmLogo.png';

const MobileWelcome = () => {
    
    return (    
        <div className='AppInterface bg-white flex flex-col justify-center items-center p-12' style={{ width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: 9999, overflow: 'hidden' }}>
            
            <img src={gmlogo} alt="GrantMagic Logo" className="w-14 h-14 mb-4" />
            <div className="text-xl font-bold">
                Please access GrantMagic on a desktop device.
            </div>
            <div className='text-gray-500 my-4'>We do not currently support mobile.</div>            
            <button onClick={() => window.location.href = 'https://www.trygrantmagic.com'} className="mt-4 bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded">
                Visit Landing Page
            </button>
        </div>
    );
};

export default MobileWelcome;