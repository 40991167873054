import React, { useState } from 'react';
import MfdComponentSpecificationModal from './MfdComponentSpecificationModal'; // Import the new modal

const MfdComponentSelectorModal = ({
    setShowMfdComponentSelectorModal,
    currentProgram,
    currentProposal,
    setShowLoadingModal,
    refreshProposalComponents
}) => {
    const [showMfdComponentSpecificationModal, setShowMfdComponentSpecificationModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setShowMfdComponentSpecificationModal(true);
    };

    return (
        <div className="bg-gray-900 bg-opacity-50" 
             style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: '9999', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
             onClick={() => setShowMfdComponentSelectorModal(false)}
        >
            <div className='text-left bg-white rounded-lg p-16' 
                 style={{ width: '60vw', height: '60vh', overflowY: 'auto' }}
                 onClick={(e) => e.stopPropagation()}>
                
                <div className="flex w-full text-center text-gray-500 p-4">
                    <p className='text-3xl font-bold'>Choose a document to generate</p>                                        
                </div>
                
                <div className="mt-4">
                    <div className="cursor-pointer hover:bg-gray-200 p-4 rounded-lg" onClick={() => handleOptionClick('Cover Letter')}>
                        <h2 className="text-xl font-bold">✉️ Cover Letter ➡️</h2>
                        <p>Generate a professional cover letter tailored for your grant application</p>
                    </div>
                    <div className="cursor-pointer hover:bg-gray-200 p-4 rounded-lg" onClick={() => handleOptionClick('Budget')}>
                        <h2 className="text-xl font-bold">💰 Budget ➡️</h2>
                        <p>Outline a detailed budget plan to accompany your grant proposal</p>
                    </div>
                    <div className="cursor-pointer hover:bg-gray-200 p-4 rounded-lg" onClick={() => handleOptionClick('LOI')}>
                        <h2 className="text-xl font-bold">📝 Letter of Intent (LOI) ➡️</h2>
                        <p>Compose a compelling letter of intent to express your interest in a grant</p>
                    </div>
                    <div className="cursor-pointer hover:bg-gray-200 p-4 rounded-lg" onClick={() => handleOptionClick('Custom')}>
                        <h2 className="text-xl font-bold">✏️ Custom ➡️</h2>
                        <p>Enter your own specifications to generate a custom document for your needs</p>
                    </div>
                </div>
            </div>

            {showMfdComponentSpecificationModal && (
                <MfdComponentSpecificationModal 
                    selectedOption={selectedOption} 
                    setShowMfdComponentSpecificationModal={setShowMfdComponentSpecificationModal} 
                    currentProgram={currentProgram}
                    currentProposal={currentProposal}
                    refreshProposalComponents={refreshProposalComponents}
                    setShowLoadingModal={setShowLoadingModal}
                    setShowMfdComponentSelectorModal={setShowMfdComponentSelectorModal}
                />
            )}
        </div>
    );
};

export default MfdComponentSelectorModal;
