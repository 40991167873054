import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import ComponentVersionHistoryModal from './ComponentVersionHistoryModal';
import AiComponentEditorModal from './AiComponentEditorModal';
import { deleteGrantProposalComponentInFirestore, fetchProposalComponentsFromFirestore, updateComponentTextInFirestore, createComponentTextBackupInFirestore } from '../../utilityFunctions/firestoreUtilities';

const ProposalComponent = ({ 
    component, 
    setShowLoadingModal,
 }) => { 

    const [unsavedChangesPresent, setUnsavedChangesPresent] = useState(false);
    const [currentResponseText, setCurrentResponseText] = useState(component.responseText);
    const [showVersionHistoryModal, setShowVersionHistoryModal] = useState(false);
    const [showAiComponentEditorModal, setShowAiComponentEditorModal] = useState(false);
    const [prepopulatedInstructions, setPrepopulatedInstructions] = useState('');

    useEffect(() => {
        setCurrentResponseText(component.responseText);
    }, [component.responseText]);

    const handleProposalComponentDeletion = (componentId) => {
        if (!window.confirm('Are you sure you want to delete this component?')) return;
        deleteGrantProposalComponentInFirestore(componentId);
        fetchProposalComponentsFromFirestore();
    }

    const debouncedHandleTextUpdate = useCallback(
        debounce(async (componentId, newResponseText) => {
            await updateComponentTextInFirestore(componentId, newResponseText);
            await createComponentTextBackupInFirestore(componentId, newResponseText);
            setUnsavedChangesPresent(false)
        }, 1000),
        [] // Empty dependency array ensures this is only created once
    );

    const handleTextChange = (componentId, newResponseText) => {
        setUnsavedChangesPresent(true); 
        setCurrentResponseText(newResponseText);
        debouncedHandleTextUpdate(componentId, newResponseText); 
    };

    const handleVersionHistoryButtonClick = () => {
        setShowVersionHistoryModal(true); 
    };    

    return (
        <div className="bg-white p-4 my-8 rounded-lg outline outline-gray-200 outline-1">
            
            {/* Requirement Section */}
            <p className='text-left text-xs font-bold mt-4 ml-1 mb-1'>DESCRIPTION</p>
            <div className='text-left mb-10 font-bold bg-gray-100 rounded-lg px-4 py-4'>
                {component.proposalRequirement}
            </div>

            {/* Response Section */}
            <p className='text-left text-xs font-bold mt-4 ml-1 mb-1'>RESPONSE</p>
            <div className=" bg-orange-100 rounded-lg ">
                <div className="h-50">
                    {unsavedChangesPresent ? <p className="text-red-500 text-right ml-auto h-4 pr-4 pt-4 italic">Autosaving...</p> : <p className='h-4'></p>}
                </div>                                
                <div className="p-6 mb-12">
                    <textarea 
                        value={currentResponseText} 
                        onChange={(e) => handleTextChange(component.id, e.target.value)} 
                        className="border border-black mt-4 p-4 w-full h-60" 
                    />
                    
                    {/* Buttons Section */}
                    <div className='flex justify-between'>
                        {/* AI Buttons */}
                        <div className="flex space-x-4 float-right">
                            <button 
                                onClick={() => { console.log(component); setShowAiComponentEditorModal(true); }} 
                                className="cursor-pointer text-sm mt-2 py-1 px-4 rounded-xl bg-gray-400 hover:bg-indigo-500 text-white"
                            >
                                AI Edit 🪄
                            </button>
                            <button 
                                onClick={() => { 
                                    console.log(component); 
                                    setShowAiComponentEditorModal(true); 
                                    setPrepopulatedInstructions("Lengthen this text by 50%");
                                }} 
                                className="cursor-pointer text-sm mt-2 py-1 px-4 rounded-xl bg-gray-400 hover:bg-indigo-500 text-white"
                            >
                                AI Lengthen 🪄
                            </button>
                            <button 
                                onClick={() => { 
                                    console.log(component); 
                                    setShowAiComponentEditorModal(true); 
                                    setPrepopulatedInstructions("Shorten this text by 50%");
                                }} 
                                className="cursor-pointer text-sm mt-2 py-1 px-4 rounded-xl bg-gray-400 hover:bg-indigo-500 text-white"
                            >
                                AI Shorten 🪄
                            </button>
                        </div>

                    {/* Other Buttons */}
                    <div className="flex space-x-4 float-right">
                        
                        <button 
                            onClick={handleVersionHistoryButtonClick} 
                            className="cursor-pointer text-sm mt-2 py-1 px-4 rounded-xl bg-gray-400 hover:bg-blue-500 text-white"
                        >
                            History
                        </button>
                        <button 
                            onClick={() => handleProposalComponentDeletion(component.id)} 
                            className="cursor-pointer text-sm mt-2 py-1 px-4 rounded-xl bg-gray-400 hover:bg-red-500 text-white"
                        >
                            Delete
                        </button>
                    </div>                                          
                </div>
                    
                    {showVersionHistoryModal && (
                        <ComponentVersionHistoryModal 
                            componentId={component.id}
                            setShowVersionHistoryModal={setShowVersionHistoryModal}
                        />
                    )}

                    {showAiComponentEditorModal && (
                        <AiComponentEditorModal 
                            setShowAiComponentEditorModal={setShowAiComponentEditorModal}    
                            setShowLoadingModal={setShowLoadingModal}
                            currentResponseText={currentResponseText}                                                                                    
                            prepopulatedInstructions={prepopulatedInstructions}
                            setPrepopulatedInstructions={setPrepopulatedInstructions}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProposalComponent;