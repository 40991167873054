import React from 'react';

// Import AGGrid Components
import { AgGridReact } from 'ag-grid-react'; 
import 'ag-grid-community/styles/ag-grid.css'; 
import 'ag-grid-community/styles/ag-theme-alpine.css'; 

function AwardsAdvancedSearchResults({
    fetchedAwards,
    selectedOrganizationEin,
    setSelectedOrganizationEin,
    setShowOpportunityAndFunderModal
}) {

    const awardsGridColumnDefs = [
        { 
            headerName: "Tax Year", 
            field: "tax_year",
            width:100
        },        
        { 
            headerName: "Funder", 
            field: "funder_name",
            filter: "agTextColumnFilter",
            cellStyle: {fontWeight: 'bold', color: '#4338CA', cursor: 'pointer'}
        },
        { 
            headerName: "Recipient", 
            field: "recipient_name",
            filter: "agTextColumnFilter",            
            cellStyle: {fontWeight: 'bold', color: '#4338CA', cursor: 'pointer'}
        },
        { 
            headerName: "Size", 
            field: "cash_grant_amt",
            valueFormatter: ({ value }) => value ? `$${parseFloat(value).toLocaleString()}` : ''
        },
        { 
            headerName: "Purpose", 
            field: "purpose",            
        },
        { 
            headerName: "Recipient City", 
            field: "city_name",            
        },
        { 
            headerName: "Recipient State", 
            field: "state_abbreviation_cd",            
        },
    ];

    const handleOrganizationCellClicked = (params) => {

        const { colDef, data } = params;        
        let einOfClickedOrganization = '';

        if (colDef.field === 'funder_name') {
            console.log("Funder clicked. EIN: ", data.funder_ein);
            setSelectedOrganizationEin(data.funder_ein);
        } else if (colDef.field === 'recipient_name') {
            console.log("Recipient clicked. EIN: ", data.recipient_ein);
            setSelectedOrganizationEin(data.recipient_ein);
        }

        if (einOfClickedOrganization === selectedOrganizationEin) {
            setShowOpportunityAndFunderModal(true);
        }        
    };

    return (
        <div className="text-left mt-4">
            <div className="ag-theme-alpine" style={{ height: 800, width: '100%' }}>
                <AgGridReact
                    rowData={fetchedAwards}
                    columnDefs={awardsGridColumnDefs}
                    onCellClicked={handleOrganizationCellClicked}
                />
            </div>
        </div>
    );
}

export default AwardsAdvancedSearchResults;