import React, { useEffect } from 'react';
import ProposalTextComponent from './ProposalComponent';

const ProposalEditor = ({     
    currentProposal,
    setShowLoadingModal,
    setShowMfdComponentSelectorModal,
    refreshProposalComponents,
    currentProposalComponents,
}) => {
    
    useEffect(() => {
        console.log("type of refreshProposalComponents: ", typeof refreshProposalComponents);
        refreshProposalComponents()
    }, [currentProposal]);

    return (
        <div className="py-2 h-screen">
            {(currentProposalComponents?.length ?? 0) === 0 && (
                <div className='w-full p-12 bg-gray-100 text-center text-gray-500 mb-6'>
                    <p className='text-lg font-bold'>Want help writing this grant?</p>
                    <p className='text-sm'>Daisy can generate first draft proposals instantly, and edit your writing to make it more effective.</p>
                    <button onClick={() => setShowMfdComponentSelectorModal(true)} className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded mt-6">Get Started</button>
                </div>
            )}
            
            {/* Components Section */}
            {(currentProposalComponents?.length ?? 0) > 0 && (
                <div>
                    <div className="flex justify-between items-center w-full mt-6">
                        <p className="text-3xl font-bold text-left">Proposal Components ({currentProposalComponents.length})</p>   
                        <button onClick={() => setShowMfdComponentSelectorModal(true)} className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded">Add More</button>
                    </div>
                    {currentProposalComponents.map((component) => (
                        <ProposalTextComponent 
                            component={component} 
                            setShowLoadingModal={setShowLoadingModal}            
                        />
                    ))}
                    <div className='w-full p-12 bg-gray-100 text-center text-gray-500 mb-6'>
                        <p className='text-lg font-bold'>Add more components</p>
                        <p className='text-sm'>Add more components to your grant proposal to help you write it faster.</p>
                        <button onClick={() => setShowMfdComponentSelectorModal(true)} className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded mt-6">Add Component</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ProposalEditor;