import React from 'react';
import ProposalDetailsSection from '../OrganizerTools/ProposalDetailsSection';
import ProposalEditor from './ProposalEditor';

const SelectedProposalPage = ({     
    currentProposal,
    refreshGrantProposals,
    setCurrentProposal,
    setCurrentView,
    setShowLoadingModal,
    setShowManageProposalModal,
    setShowMfdComponentSelectorModal,
    refreshProposalComponents,
    currentProposalComponents,
}) => {

    const homeButtonClicked = () => {
        setCurrentView('proposalsDashboard');
        setCurrentProposal(null);
    } 

    return (
        <div className="p-12 h-screen">
            <div className="">
                <div>
                    <button className='block text-sm text-gray-500 text-left mb-4' onClick={homeButtonClicked}>Return Home</button>
                    <div className="flex justify-between items-center w-full mt-6">
                        <p className="text-3xl font-bold text-left">{currentProposal?.name}</p>   
                        <button onClick={() => setShowManageProposalModal(true)} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Edit Details</button>
                    </div>                    
                </div>
            </div>

            <ProposalDetailsSection 
                currentProposal={currentProposal}
                refreshGrantProposals={refreshGrantProposals}
                setShowManageProposalModal={setShowManageProposalModal}
                
            />

            <ProposalEditor 
                currentProposal={currentProposal}
                setShowLoadingModal={setShowLoadingModal}
                setShowMfdComponentSelectorModal={setShowMfdComponentSelectorModal}     
                refreshProposalComponents={refreshProposalComponents}
                currentProposalComponents={currentProposalComponents}
            />
        </div>
    );
}

export default SelectedProposalPage;