import React from 'react';

const OpportunityProfile = ({ 
    selectedOpportunity, 
    membershipDetails,
}) => {

    return (
        selectedOpportunity ? (
            <div className='p-12'>
                <div className='text-sm'>
                    <div className="ml-6 mt-12 text-3xl font-bold">{selectedOpportunity.grant_name}</div>
                    <div className="ml-6 mt-4"><a className='text-blue-800' href={selectedOpportunity.primary_url} target="_blank" rel="noopener noreferrer">Visit Website</a></div>
                    
                    {/* Score Div */}
                    <div className={`m-6 ${
                        membershipDetails 
                            ? selectedOpportunity.leadScore.includes('Bad') 
                                ? 'bg-red-100' 
                                : selectedOpportunity.leadScore.includes('Good') 
                                    ? 'bg-green-200' 
                                    : 'bg-orange-100' 
                            : 'bg-indigo-500 text-white'
                    } p-6 rounded-lg`}>
                        <p className='font-bold text-lg'>{membershipDetails ? selectedOpportunity.leadScore : <>🔓 Match score for Premium Plan only. <a href="https://satodadj.gumroad.com/l/rlqzat" className='underline' target="_blank" rel="noopener noreferrer">Upgrade now</a>.</>}</p>
                        <p>{membershipDetails ? selectedOpportunity.leadScoreResponse : ''}</p>
                    </div>

                    {/* Basic Information */}
                    {selectedOpportunity.grant_overview && (
                        <div className="m-6"><strong>Grant Overview</strong><br /><span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedOpportunity.grant_overview }} /></div>
                    )}
                    {selectedOpportunity.grant_size_min || selectedOpportunity.grant_size_max ? (
                        <div className="m-6"><strong>Grant Size Range:</strong><br />${selectedOpportunity.grant_size_min ? Number(selectedOpportunity.grant_size_min).toLocaleString('en-US', { minimumFractionDigits: 0 }) : 'N/A'} - ${selectedOpportunity.grant_size_max ? Number(selectedOpportunity.grant_size_max).toLocaleString('en-US', { minimumFractionDigits: 0 }) : 'N/A'}</div>
                    ) : null}
                    {selectedOpportunity.ein && (
                        <div className="m-6"><strong>Employer Identification Number (EIN):</strong><br />{selectedOpportunity.ein}</div>
                    )}
                    {selectedOpportunity.contact_information && (
                        <div className="m-6"><strong>Contact Info:</strong><br />{selectedOpportunity.contact_information}</div>
                    )}

                    {/* Requirements */}
                    {selectedOpportunity.organization_requirements && (
                        <div className="m-6"><strong>Organization Requirements:</strong><br /><span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedOpportunity.organization_requirements }} /></div>
                    )}
                    {selectedOpportunity.funding_requirements && (
                        <div className="m-6"><strong>Funding Requirements:</strong><br /><span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedOpportunity.funding_requirements }} /></div>
                    )}

                    {/* Preferences */}
                    {selectedOpportunity.organization_preferences && (
                        <div className="m-6"><strong>Organization Preferences:</strong><br /><span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedOpportunity.organization_preferences }} /></div>
                    )}
                    {selectedOpportunity.funding_preferences && (
                        <div className="m-6"><strong>Funding Preferences:</strong><br /><span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedOpportunity.funding_preferences }} /></div>
                    )}

                    {/* Application Details */}
                    {selectedOpportunity.application_components && (
                        <div className="m-6"><strong>Application Components:</strong><br /><span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedOpportunity.application_components }} /></div>
                    )}
                    {selectedOpportunity.selection_criteria && (
                        <div className="m-6"><strong>Selection Criteria:</strong><br /><span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedOpportunity.selection_criteria }} /></div>
                    )}
                    {selectedOpportunity.how_to_apply && (
                        <div className="m-6"><strong>How to Apply:</strong><br /><span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedOpportunity.how_to_apply }} /></div>
                    )}
                    {selectedOpportunity.important_dates && (
                        <div className="m-6"><strong>Important Dates:</strong><br /><span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedOpportunity.important_dates }} /></div>
                    )}

                    {/* Additional Information */}
                    {selectedOpportunity.additional_notes && (
                        <div className="m-6">
                            <strong>Other Timing Information:</strong><br />
                            <span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedOpportunity.additional_notes }} />
                        </div>
                    )}
                    {selectedOpportunity.funder_details && (
                        <div className="m-6"><strong>Funder Details:</strong><br /><span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedOpportunity.funder_details }} /></div>
                    )}
                </div>
                <p className='ml-6 italic text-xs text-gray-500'>
                    Created at: {selectedOpportunity.leadScoreCreationTimestamp ? new Date(selectedOpportunity.leadScoreCreationTimestamp).toLocaleDateString() : 'N/A'}. 
                    Last updated: {/* Assuming there's no updatedAt field in the new object shape */}
                </p>
            </div>
        ) : (
            <div className='p-24 text-center flex items-center justify-center text-gray-500'>
                No opportunity selected
            </div>
        )
    );
};

export default OpportunityProfile;
