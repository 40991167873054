const ProposalDetailsSection = ({             
        currentProposal,
    }) => {    

    return (
        <div className='text-left bg-white rounded-lg' 
             style={{ width: '100%', height: 'auto', overflowY: 'auto' }}
             >
            
            <table className='mt-6 w-full'>
                <tbody>
                    <tr className='h-8'>
                        <td className='text-gray-500 mb-1 w-1/6'>Status</td>
                        <td className="p-2 text-left relative group flex items-center">
                            <span>{currentProposal.status}</span>
                        </td>
                    </tr>
                    <tr className='h-8'>
                        <td className='text-gray-500 mb-1 w-1/6'>Amount</td>
                        <td className="p-2 text-left relative group flex items-center">
                            <span>
                                {isNaN(currentProposal.amount) ? '' : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(currentProposal.amount)}
                            </span>
                        </td>
                    </tr>
                    
                    <tr className='h-8'>
                        <td className='text-gray-500 mb-1 w-1/6'>Win %</td>
                        <td className="p-2 text-left relative group flex items-center">
                            <span>
                                {isNaN(currentProposal.chance) ? '' : `${currentProposal.chance}%`}
                            </span>
                        </td>
                    </tr>
                    <tr className='h-8'>
                        <td className='text-gray-500 mb-1 w-1/6'>Expected Value</td>
                        <td className="p-2 text-left relative group flex items-center">
                            <span>
                                {isNaN(currentProposal.amount) || isNaN(currentProposal.chance) ? '' : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format((currentProposal.amount * currentProposal.chance) / 100)}
                            </span>
                        </td>
                    </tr>

                    <tr className='h-8'>
                        <td className='text-gray-500 mb-1 w-1/6'>Deadline</td>
                        <td className="p-2 text-left relative group flex items-center">
                            {currentProposal.deadline ? (
                                <span>{new Date(currentProposal.deadline.seconds * 1000).toLocaleDateString()}</span>
                            ) : (
                                <span> </span>
                            )}
                        </td>
                    </tr>
                    <tr className='h-8'>
                        <td className='text-gray-500 mb-1 w-1/6'>Notes</td>
                        <td className="p-2 text-left relative group flex items-center">
                            <span>{currentProposal.notes}</span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <hr className="my-4" />
        </div>
    );
};

export default ProposalDetailsSection;
