import { doc, updateDoc, Timestamp } from "firebase/firestore";
import db from '../../config/firebaseConfig';
import { useState } from 'react';

const ManageProposalModal = ({             
        currentProposal,
        fetchGrantProposalByIdFromFirestore,
        setCurrentProposal,
        setShowManageProposalModal
    }) => {

    // Convert Firestore Timestamp to Date string
    const convertTimestampToDate = (timestamp) => {
        if (timestamp && timestamp.seconds) {
            const date = new Date(timestamp.seconds * 1000);
            return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
        }
        return '';
    };

    const [proposalDeadline, setProposalDeadline] = useState(convertTimestampToDate(currentProposal.deadline));

    const handleProposalUpdate = async () => {
        const name = document.getElementById('proposal-name').value;
        const status = document.getElementById('proposal-status').value;
        const notes = document.getElementById('proposal-notes').value;
        const amount = document.getElementById('proposal-amount').value;
        const chance = document.getElementById('proposal-chance').value;

        try {
            console.log("updating Grant Proposal document in Firestore")
            console.log("Name: ", name, "Status: ", status, "Notes: ", notes, "Amount: ", amount, "Chance: ", chance, "Proposal Deadline: ", proposalDeadline)
            const proposalRef = doc(db, 'grantProposals', currentProposal.id);
            await updateDoc(proposalRef, {
                name: name,
                status: status,
                notes: notes,
                amount: amount,
                chance: chance,
                deadline: Timestamp.fromDate(new Date(proposalDeadline))
            });
            console.log('Proposal updated successfully');
        } catch (error) {
            console.error('Error updating proposal: ', error);
        }

        const updatedProposal = await fetchGrantProposalByIdFromFirestore(currentProposal.id)
        console.log("updatedProposal: ", updatedProposal)
        setCurrentProposal(updatedProposal)
        setShowManageProposalModal(false)
    };

    return (
        <div className="bg-gray-900 bg-opacity-50" 
            style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: '9999', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            onClick={() => setShowManageProposalModal(false)}
        >
        
            {/* Modal */}
            <div className='text-left bg-white rounded-lg p-12' 
             style={{ width: '80vw', height: '80vh', overflowY: 'auto' }}
             onClick={(e) => e.stopPropagation()}
             >
            
                <p className='text-2xl text-center'>Manage Proposal</p>
                
                <div className='mt-6'>
                    <p className='font-bold text-gray-500 mb-1'>NAME</p>
                    <input id="proposal-name" type="text" defaultValue={currentProposal.name} 
                           className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full" />
                </div>

                <div className='mt-6'>
                    <p className='font-bold text-gray-500 mb-1'>STATUS</p>
                    <select id="proposal-status" defaultValue={currentProposal.status} 
                            className="mt-1 border border-gray-300 rounded-md shadow-sm p-1 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full">
                        <option value="Saved">Saved</option>
                        <option value="In Progress">In Progress</option>
                        <option value="Submitted">Submitted</option>
                        <option value="Won">Won</option>
                        <option value="Lost">Lost</option>
                    </select>
                </div>
                
                <div className='mt-6'>
                    <p className='font-bold text-gray-500 mb-1'>AMOUNT</p>
                    <input id="proposal-amount" type="number" defaultValue={currentProposal.amount} 
                           className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full" />
                </div>
                
                <div className='mt-6'>
                    <p className='font-bold text-gray-500 mb-1'>% LIKELIHOOD OF WINNING</p>
                    <input id="proposal-chance" type="number" placeholder="0-100" min="0" max="100" defaultValue={currentProposal.chance} 
                           className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full" />
                </div>

                <div className='mt-6'>
                    <p className='font-bold text-gray-500 mb-1'>DEADLINE</p>
                    <input type="date" value={proposalDeadline} onChange={(e) => setProposalDeadline(e.target.value)} 
                           className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full" />
                </div>
                <div className='mt-6'>
                    <p className='font-bold text-gray-500 mb-1'>NOTES</p>
                    <input id="proposal-notes" type="text" defaultValue={currentProposal.notes} 
                           className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full" />
                </div>

                <div className="mt-4 space-x-4">
                    <button onClick={handleProposalUpdate} className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded">Update</button>
                    <button onClick={() => setShowManageProposalModal(false)} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Cancel</button>                    
                </div>                
            </div>
        </div>
    );
};

export default ManageProposalModal;
