// src/pages/Signup.js
import React, { useState } from 'react';
import { createUserWithEmailAndPassword  } from 'firebase/auth';
import { Link } from 'react-router-dom';
import {doc, setDoc} from "firebase/firestore";
import db, { auth } from '../../config/firebaseConfig';


function Signup() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [discoveryMethod, setDiscoveryMethod] = useState('')

  const handleSignUp = (e) => {
    e.preventDefault();

    createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      console.log(userCredential);
      
      // Create a user document in Firestore            
      const userRef = doc(db, "users", user.uid);
      setDoc(userRef, {
        email: user.email,
        uid: user.uid,
        discoveryMethod: discoveryMethod,
        signUpDate: new Date(),
      })
      .then(() => {
        window.location.href = '/';
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
        alert(error)
      });
    }).catch((error) => {
      console.error(error);
      alert(error)
    });
  }
  
  return (
    <div className="flex min-h-full flex-col items-center justify-center px-6 py-12 lg:px-8">

      <div className="self-start" onClick={() => window.location.href = 'http://www.withgrantmagic.com'} >
        <button onClick={() => window.location.href = 'http://www.withgrantmagic.com'} className="w-30 h-12 p-2">
          Return Home
        </button>
      </div>


      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h3 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight">
          Welcome to GrantMagic.
        </h3>
        <h3 className="mt-2 text-center text-xl leading-9 tracking-tight text-gray-500">
          You're one click away from fundraising superpowers. 
        </h3>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" action="#" method="POST" onSubmit={handleSignUp}>
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-left">
              Email Address<span className="text-indigo-600"> *</span>
            </label>
            <div className="mt-2">
              <input
                value={email}
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
                required
                className="block w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6">
                Password<span className="text-indigo-600"> *</span>
              </label>
            </div>
            <div className="mt-2">
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="block w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label htmlFor="discoveryMethod" className="block text-sm font-medium leading-6 text-left">
              How did you hear about us?<span className="text-indigo-600"> *</span>
            </label>
            <div className="mt-2">
              <input
                value={discoveryMethod}
                onChange={(e) => setDiscoveryMethod(e.target.value)}
                id="discoveryMethod"
                name="discoveryMethod"
                type="text"
                required
                className="block w-full rounded-md border-0 pl-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>          

          <div>
            <button
              type="submit"
              className={`flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-indigo-600 hover:bg-indigo-500' focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
            >
              Sign Up
            </button>
          </div>
        </form>

        <p className="mt-10 text-center text-sm text-gray-500">
          Already a member?{' '}
          <Link to="/login" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
            Sign in now.
          </Link>
        </p>
      </div>
    </div >

  );
}

export default Signup;