    import React, { useContext, useEffect } from 'react';
    import { AuthContext } from '../AuthContext';
    import { Link } from 'react-router-dom';

    const SideNavBar = ({ 
        currentProgram,
        currentView,
        membershipDetails,
        organizationPrograms,
        setCurrentProgram,
        setCurrentView, 
        setProgramToModify,
        setShowCreateProgramModal,
        setShowPaywallModal,
    }) => {

        const { authUser, handleSignOut } = useContext(AuthContext);    

        // const handleOrganizationButtonClicked = () => {
        //     setShowClientOrganizationsPickerModal(true);
        // };

        // Methods for Program Selection Header
        useEffect(() => {
            console.log("Organization Programs:", organizationPrograms);
        }, [organizationPrograms]);
        const handleCreateProgramButtonClicked = () => {
            if (!membershipDetails) {
                setShowPaywallModal(true);
            } else {
                console.log("Create program")
                setProgramToModify(null)
                setShowCreateProgramModal(true)
            }
        };

        const handleManageProgramButtonClicked = () => {
            setProgramToModify(currentProgram)
            setShowCreateProgramModal(true);        
        };
    

        const handleProgramSelected = (program) => {
            console.log("Program selected: ", program);
            setCurrentProgram(program);
            setCurrentView('programDashboard');
        };

        return (
            <div className='bg-gray-300 text-left' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '300px', height: '100vh' }}>
                <div className='p-6'>
                    <div className='w-full flex flex-col'>
                        <button className='text-2xl mt-6 text-left' style={{fontStyle: 'cursive', fontWeight: '900'}} onClick={() => setCurrentView('proposalsDashboard')}>🪄 GrantMagic</button>
                        <p className='text-sm text-gray-700 mt-2'>AI tools for nonprofits</p>
                        {/* <a href="https://ossified-pluto-bdd.notion.site/GrantMagic-Change-Log-30bcd234d85542a5bd30ca73f2d9fcb9?pvs=4" className='italic text-gray-700 mt-2' target="_blank" rel="noopener noreferrer">Version 0.4.0</a> */}
                    </div>
                    {authUser ? (
                        <div className='w-full' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', height: '100vh' }}>                                               
                            <select 
                                className='mt-7 rounded-lg hover:bg-gray-200 flex items-center border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 w-full'
                                value={currentProgram ? currentProgram.id : ''}
                                onChange={(e) => {
                                    const selectedProgramId = e.target.value;
                                    const selectedProgram = organizationPrograms.find(program => program.id === selectedProgramId);
                                    handleProgramSelected(selectedProgram);
                                }}
                            >
                                {organizationPrograms && organizationPrograms.length > 0 && organizationPrograms.map(program => (
                                    <option key={program.id} value={program.id}>
                                        {program.programName}
                                    </option>
                                ))}
                            </select>
                            <div className='flex gap-2 mt-2'>
                                <button 
                                    className='bg-gray-200 p-2 text-sm rounded-lg'
                                    onClick={handleCreateProgramButtonClicked}
                                >
                                    Create New
                                </button>
                                <button className='bg-gray-200 p-2 text-sm rounded-lg' onClick={handleManageProgramButtonClicked}>Manage</button>
                            </div>
                                
                            
                            <div className="w-full mt-8 text-2xl">
                                <button 
                                    className={`w-full py-3 text-left ${(currentView === 'proposalsDashboard' || currentView === 'selectedProposalPage') ? 'font-bold' : ''}`} 
                                    onClick={() => setCurrentView('proposalsDashboard')}
                                >
                                    🏠  Proposals
                                </button>
                                <button className={`w-full py-3 text-left ${currentView === 'rfpsSearch' ? 'font-bold' : ''}`} onClick={() => setCurrentView('rfpsSearch')}>🎁 Grant RFPs</button>
                                <button className={`w-full py-3 text-left ${currentView === 'awardsAdvancedSearch' ? 'font-bold' : ''}`} onClick={() => setCurrentView('awardsAdvancedSearch')}>🔍 Past Awards</button>                            
                            </div>
                            <div className='w-full flex flex-col text-md mt-6 text-gray-700 '>
                                <button className='mb-1 text-left hover:text-gray-900' onClick={() => setCurrentView('accountSettings')}>Account</button>
                                <a className='mb-1 hover:text-gray-900' href="https://tally.so/r/mDVa5j" target="_blank" rel="noopener noreferrer">Support</a>                    
                                <a className='mb-1 hover:text-gray-900' href="#" onClick={handleSignOut} >Logout</a>    
                            </div>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <h2 className="font-bold mt-10">Settings</h2>
                            <a href="https://tally.so/r/mDVa5j" className="" target="_blank" rel="noopener noreferrer">Support</a>
                            <Link to="/signup" className="">Sign Up</Link>          
                            <Link to="/login" className="">Log In</Link>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    export default SideNavBar;
