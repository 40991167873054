import { createClient } from '@supabase/supabase-js';

// Initialize Supabase client
const supabaseUrl = 'https://jlukxfltibmxaonhripo.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImpsdWt4Zmx0aWJteGFvbmhyaXBvIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTcyOTYwOTI0NiwiZXhwIjoyMDQ1MTg1MjQ2fQ.azWLdoVXRgEggw9XoGQJUecPAzqxlg4rdP0LkBZpI3w';
const supabase = createClient(supabaseUrl, supabaseAnonKey);

export const fetchRFPsFromSupabase = async () => {
    console.log("Fetching RFPs from Supabase.");
    try {
        const { data, error } = await supabase
            .from('rfps') // Replace 'rfps' with your actual table name
            .select('*');

        if (error) {
            throw error;
        }

        console.log("RFPs from Supabase:", data);
        return data; // Return the fetched data
    } catch (error) {
        console.error("Error fetching RFPs from Supabase:", error);
        throw error;
    }
};

export const fetchRfpScoresFromSupabase = async (programId) => {
    console.log("Fetching RFP scores from Supabase for program: ", programId);
    try {
        const { data, error } = await supabase
            .from('rfp_scores') // Replace 'rfps' with your actual table name
            .select('*')
            .eq('program_id', programId);

        if (error) {
            throw error;
        }
        console.log("RFP scores from Supabase:", data);
        return data; // Return the fetched data
    } catch (error) {
        console.error("Error fetching RFP scores from Supabase:", error);
        throw error;
    }
};