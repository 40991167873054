import React, { useEffect, useState } from 'react';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import db from '../../config/firebaseConfig';

const ComponentVersionHistoryModal = ({ 
    componentId, 
    setShowVersionHistoryModal 
}) => {
    const [backups, setBackups] = useState([]);

    const fetchBackups = async () => {
        try {
            const q = query(
                collection(db, 'grantProposalComponentBackups'),
                where('componentId', '==', componentId),
                orderBy('createdAt', 'desc')
            );
            const querySnapshot = await getDocs(q);
            const docs = querySnapshot.docs.map(doc => doc.data());
            setBackups(docs);
        } catch (error) {
            console.error("Error fetching backups:", error);
        }
    };

    useEffect(() => {
        fetchBackups();
    }, [componentId]);

    return (
        <div className="bg-gray-900 bg-opacity-50" 
        style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: '9999', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        onClick={() => setShowVersionHistoryModal(false)}
   >
       
       {/* Modal */}
       <div className='text-left bg-white rounded-lg p-8' 
            style={{ width: '80vw', height: '80vh', overflowY: 'auto' }}
            onClick={(e) => e.stopPropagation()}>
                <div className=''>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button onClick={() => setShowVersionHistoryModal(false)} className="py-2 px-4 mb-4 bg-gray-500 text-white rounded-lg">Close</button>
                    </div>
                    <div>
                        {backups.map((doc, index) => (
                            <div key={index} className="text-left mb-4">
                                <p className='text-gray-400 italic text-sm'>{new Date(doc.createdAt.toDate()).toLocaleString()}</p>
                                <p>{doc.responseText}</p>
                                {index < backups.length - 1 && <hr className='mt-4'/>}
                            </div>
                        ))}
                    </div>
                </div>                
            </div>
        </div>
    );
};

export default ComponentVersionHistoryModal;